<template>
  <div class="user_tj">
    <el-row :gutter="10">
      <el-col :span="8">
        <el-card>
          <div class="title">年度消费</div>
          <el-divider></el-divider>
          <div class="content">
            <div class="xiaofei">{{user.nd_xiaofei | digit}}</div>
            <div class="compare">
              <div>周同比<i :class="['icons',user.ztb_status==0?'el-icon-caret-bottom':'el-icon-caret-top']" :style="user.ztb_status == 0 ? 'color:red' : 'color:green'"></i>{{user.ztb}}%</div>
              <div>日同比<i :class="['icons',user.rtb_status==0?'el-icon-caret-bottom':'el-icon-caret-top']" :style="user.rtb_status == 0 ? 'color:red' : 'color:green'"></i>{{user.rtb}}%</div>
            </div>
            <div class="today-xiaofei">
              <el-divider></el-divider>
              <div>今日消费:{{user.today_xiaofei | digit}}</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <div class="title">订单总数</div>
          <el-divider></el-divider>
          <div class="content1">
            <div class="order_all">{{user.order_total | digits}}</div>
            <div class="order_today">
              <el-divider></el-divider>
              <div>今日订单数:{{user.order_today | digits}}</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <div class="title">用户总数</div>
          <el-divider></el-divider>
          <div class="content1">
            <div class="order_all">{{user.order_total | digits}}</div>
            <div class="order_today">
              <el-divider></el-divider>
              <div>今日新增:{{user.user_today | digits}}</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <div class="title">用户评价</div>
          <el-divider></el-divider>
          <div class="content3">
            <el-rate
              v-model="user.evaluate.grade"
              disabled
              show-score
              text-color="#ff9900">
            </el-rate>
            <div class="num_people">
              <div class="items" v-for="(item,index) in user.evaluate.num_people" :key="index">
                {{item.name}} : {{item.count}}人
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card>
          <div class="title">最近一小时下单量</div>
          <el-divider></el-divider>
          <div class="content4" ref="line"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
let that;
import {formatDate} from "@/api/utils";
export default {
  data () {
    return {
      user:{
        nd_xiaofei:'',
        ztb:'',
        ztb_status:0,
        rtb:'',
        rtb_status:1,
        today_xiaofei:'',
        order_total:'',
        order_today:'',
        user_total:'',
        user_today:'',
        evaluate:{
          grade:0,
          num_people:[],
        },
        xaxis:[],
        xdata:[]
      },
      line:null

    }
  },
  created() {
    that = this;
  	if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		}
  },
  mounted() {
  	this.initTjdata();
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.line.resize();
    });
  },
  methods: {
  	initTjdata(){
  		var url = 'statistic_user/userTjData';
			let params = {};
			this.fd_post(url, params).then((res) => {
				console.log(res);
				if(res.status) {
					that.user = res.detail;
					that.init_line();
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
    init_line(){
      let user=this.user
      this.line=this.$echarts.init(this.$refs.line)
      this.line.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['浏览量', '访问量'],
          right:'1%',
          top:'0%'
        },
        grid: {
          left: '1%',
          right: '3%',
          bottom: '1%',
          top:'15%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: user.xaxis
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine:{
              show:false
            }
          }
        ],
        series: [
          {
            name: '下单量',
            type: 'line',
            showSymbol:false,
            itemStyle:{
              normal:{
                color:'#FFCF4A',
                lineStyle:{
                  color:'#FFCF4A'
                }
              }
            },
            areaStyle:{
              color:{
                x:0,
                y:0,
                x2:0,
                y2:3,
                colorStops:[
                  {
                    offset: 0,
                    color: "#FFCF4A",
                  },
                  {
                    offset: 0.3,
                    color: "#ffffff",
                  }
                ]
              }
            },
            data: user.xdata
          }
        ]
      })
      //宽度自适应
      window.addEventListener('resize',()=>{
        this.line.resize()
      })
    }
  },
}
</script>

<style lang='scss' scoped>
	@import "./User.scss";
</style>